import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs/Observable';
import { Subject } from "rxjs";
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';


interface FirebaseRecord {}; //define the interface as compeletely open object

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {

  private basePath: string = '/shipments';

  shipments: AngularFirestoreCollection<[]> = null; //  list of objects
  updates: AngularFirestoreCollection<[]> = null; //  list of objects
  created_at: Date;
  pickedup_at: Date;
  
  constructor(private fs: AngularFirestore, private fa: AngularFireAuth, private http: HttpClient) { }

  login(user: any): Observable<string> {
    let response:string;
    const signupSubj = new Subject<string>();
    const result = signupSubj.asObservable();
    this.fa.auth.signInWithEmailAndPassword(user.email, user.password)
      .then((fbuser) => {
        response = 'USER_LOGIN_SUCCESS';
        signupSubj.next(response);
      })
      .catch(function (err){
        response = null;
        signupSubj.next(response);
      });
    return result;
  } 

  getShipment(tracking_number: string) {

    if(tracking_number.toLocaleLowerCase().startsWith('shp_'))
      return firebase.firestore().collection('shipment.create_from_order').where('tracking_number', '==', tracking_number).get();
    else
      return firebase.firestore().collection('shipment.create_from_order').where('req_number', '==', tracking_number).get();


    //return this.fs.doc('/shipment.create_from_order/' + tracking_number);
  }

  exportCSV(fromDate: string, toDate: string)  {
    const fromDateFormat = moment(fromDate, ['YYYY-MM-DD']).format('YYYY-MM-DD');
    const toDateFormat = moment(toDate, ['YYYY-MM-DD']).format('YYYY-MM-DD');
    console.log(fromDateFormat);
    console.log(toDateFormat);
    return firebase.firestore().collection('shipment.create_from_order').orderBy('created_at').where('created_at', '>=', fromDateFormat).where('created_at', '<=', toDateFormat).get();
  }

  addTracking (obj: {}) {

    if(obj['tracking_number']) {
      obj['timestamp'] = new Date().getTime();
      console.log(obj);
      return this.fs.doc('/shipment.create_from_order/' + obj['tracking_number']).set({ noble_updates : obj }, { merge : true });
    }
    
  }

  addWorkingDays(startDate: Date, days: number) {
    if(isNaN(days)) {
        console.log("Value provided for \"days\" was not a number");
        return
    }
    if(!(startDate instanceof Date)) {
        console.log("Value provided for \"startDate\" was not a Date object");
        return
    }
    // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
    var dow = startDate.getDay();
    var daysToAdd = days;
    // If the current day is Sunday add one day
    if (dow == 0)
        daysToAdd++;
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
        //Subtract days in current working week from work days
        var remainingWorkDays = daysToAdd - (5 - dow);
        //Add current working week's weekend
        daysToAdd += 2;
        if (remainingWorkDays > 5) {
            //Add two days for each working week by calculating how many weeks are included
            daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
            //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
            if (remainingWorkDays % 5 == 0)
                daysToAdd -= 2;
        }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;    
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-with-param',
  templateUrl: './search-with-param.component.html',
  styleUrls: ['./search-with-param.component.css'],
  providers: []
})
export class SearchWithParamComponent implements OnInit {

  trackingNumber = "";
  shipment = null;
  searchForm: FormGroup;
  isLoading = false;

  constructor(private route: ActivatedRoute, private firebaseService: FirebaseService, private fb: FormBuilder, private router: Router) {
    this.trackingNumber = this.route.snapshot.paramMap.get('id');
    this.searchForm = this.fb.group({
      trackById: [this.trackingNumber, Validators.required]
    });

  }

  async ngOnInit() {
    this.isLoading = true;
    var snapshot = await this.firebaseService.getShipment(this.trackingNumber);
    const data = snapshot.docs.map(doc => doc.data());
    console.log(data);    
    this.shipment = data[0] || null;
    if (this.shipment) {
      this.shipment['pickedup_at'] = (this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 1).getTime() <= new Date().getTime()) ? this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 1) : null;
      this.shipment['delivered_at_de'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 3);
      this.shipment['delivered_at_itplchnl'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 4);
      this.shipment['delivered_at_others'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 5);
      this.shipment = this.shipment;
      console.log(this.shipment);
    }
    this.isLoading = false;
  }

  async onSubmit() {
    this.isLoading = true;
    this.trackingNumber = this.searchForm.controls['trackById'].value;
    console.log(this.trackingNumber);
    var snapshot = await this.firebaseService.getShipment(this.trackingNumber);
    const data = snapshot.docs.map(doc => doc.data());
    console.log(data);
    this.shipment = data[0] || null;
    if (this.shipment) {
      this.shipment['pickedup_at'] = (this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 1).getTime() <= new Date().getTime()) ? this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 1) : null;
      this.shipment['delivered_at_de'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 3);
      this.shipment['delivered_at_itplchnl'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 4);
      this.shipment['delivered_at_others'] = this.firebaseService.addWorkingDays(new Date(this.shipment['created_at']), 5);
      this.shipment = this.shipment;
      console.log(this.shipment);
    }
    this.router.navigate(['/search/', this.trackingNumber]);
    this.isLoading = false;
  }


}
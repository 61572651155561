// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBAMqwC6FRQ-Y-jHiGo1m1-HR9USuCOFC4",
    authDomain: "noble-tracker.firebaseapp.com",
    databaseURL: "https://noble-tracker.firebaseio.com",
    projectId: "noble-tracker",
    storageBucket: "noble-tracker.appspot.com",
    messagingSenderId: "887307700864"
  }  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchWithParamComponent } from './components/search-with-param/search-with-param.component'
import { AdminComponent } from './components/admin/admin.component'
const routes: Routes = [
  { path: '', redirectTo: '/search', pathMatch: 'full' },
  { path: 'search', component: SearchWithParamComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'search/:id', component: SearchWithParamComponent },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AngularFireList } from '@angular/fire/database';
import { Observable } from 'rxjs/Observable';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { NgxDrpOptions, PresetItem, Range } from 'ngx-mat-daterange-picker';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { FirebaseService } from '../../services/firebase.service';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  range: any;
  today = new Date();
  options: NgxDrpOptions;
  presets: Array<PresetItem> = [];
  isLoading: boolean = false;
  task: AngularFireUploadTask;
  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;

  // Download URL
  downloadURL: Observable<string>;

  constructor(private firebaseService: FirebaseService, private storage: AngularFireStorage) { }

  public loggedIn: boolean;
  private fileToUpload: File = null;
  public shipments: any;
  public recordsImported: number;
  public totalRecords: number = 0;
  items: AngularFireList<[]> = null;
  files = [];


  loginForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });


  ngOnInit() {

    var storageRef = this.storage.storage.ref('uploaded_files/').listAll().then(files => {
      files.items.forEach(async (item) => {
        const meta = await item.getMetadata();
        meta.url = await item.getDownloadURL();
        this.files.push(meta);
        this.files.sort((a, b) => (new Date(a.timeCreated).getTime() < new Date(b.timeCreated).getTime() ? 1 : -1));
      });
      
    });
    
    this.loggedIn = false;
    this.recordsImported = 0;
    const fromMin = new Date(this.today.getFullYear(), this.today.getMonth() - 2, 1);
    const fromMax = new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0);
    const toMin = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
    const toMax = new Date(this.today.getFullYear(), this.today.getMonth() + 12, 0);

    this.setupPresets();
    this.options = {
      presets: this.presets,
      format: 'mediumDate',
      range: { fromDate: this.today, toDate: this.today },
      applyLabel: "Submit",
      calendarOverlayConfig: {
        shouldCloseOnBackdropClick: false,
        hasBackdrop: true
      },
      cancelLabel: "Cancel",
    };

  }

  // handler function that receives the updated date range object
  async updateRange(range: Range) {

    this.range = range;
    console.log(this.range);
    if (this.range && (this.range.fromDate != this.today || this.range.toDate != this.today)) {
      this.isLoading = true;
      const snapshot = await this.firebaseService.exportCSV(this.range.fromDate, this.range.toDate);
      const data = snapshot.docs.map(doc => doc.data());
      var shipments = [];
      data.forEach(shipment => {
        var order = {};
        if(shipment.noble_updates) {
          order = { id: shipment.id, created_at: shipment.created_at, delivered_at: shipment.noble_updates.date, delivered_time: shipment.noble_updates.time, person_signed: shipment.noble_updates.person_signed, order_number: shipment.order_number, shid: shipment.shid, tracking_number: shipment.tracking_number }
        }
        else {
          order = { id: shipment.id, created_at: shipment.created_at, delivered_at: '', delivered_time: '', person_signed: '', order_number: shipment.order_number, shid: shipment.shid, tracking_number: shipment.tracking_number }
        }        
        shipments.push(order);
      });
/*       for(var i = 0; i <= data.length; i++) {
        console.log(data[i]);
        if(data[i].hasOwn) {
          const order = { id: data[i]['id'], created_at: data[i]['created_at'], delivered_at: data[i]['noble_updates'].date, delivered_time: data[i]['noble_updates'].time, person_signed: data[i]['noble_updates'].person_signed, order_number: data[i]['order_number'], shid: data[i]['shid'], tracking_number: data[i]['tracking_number'] }
          shipments.push(order);
        }
        else {
          const order = { id: data[i]['id'], created_at: data[i]['created_at'], delivered_at: false, delivered_time: false, person_signed: false, order_number: data[i]['order_number'], shid: data[i]['shid'], tracking_number: data[i]['tracking_number'] }
          shipments.push(order);
        }
      } */
      

      if (shipments && shipments.length > 0) {
        const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
        const header = Object.keys(shipments[0]);
        let csv = shipments.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        let csvArray = csv.join('\r\n');
        var blob = new Blob([csvArray], { type: 'text/csv' })
        saveAs(blob, "shiphawk-delivery-export.csv");
      }
      else {
        alert('No results.');
      }
      this.isLoading = false;
    }
  }



  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }



  // helper function to create initial presets
  setupPresets() {

    const backDate = (numOfDays) => {
      const today = new Date();
      return new Date(today.setDate(today.getDate() - numOfDays));
    }

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7)
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    this.presets = [
      { presetLabel: "Yesterday", range: { fromDate: yesterday, toDate: today } },
      { presetLabel: "Last 7 Days", range: { fromDate: minus7, toDate: today } },
      { presetLabel: "Last 30 Days", range: { fromDate: minus30, toDate: today } },
      { presetLabel: "This Month", range: { fromDate: currMonthStart, toDate: currMonthEnd } },
      { presetLabel: "Last Month", range: { fromDate: lastMonthStart, toDate: lastMonthEnd } }
    ]
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  uploadFile() {
    this.totalRecords = 0;
    this.recordsImported = 0;
    let fileReader = new FileReader();
    var self = this;
    fileReader.onload = (e) => {
      self.csvJSON(fileReader.result);
    }
    fileReader.readAsText(this.fileToUpload);
    const path = `uploaded_files/${new Date().getTime()}_${this.fileToUpload.name}`;
    console.log(path);
    this.task = this.storage.upload(path, this.fileToUpload);
    // Progress monitoring
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges()

  }

  csvJSON(csvText, i = 0) {

    var lines = csvText.split("\r\n");
    var headers = lines[0].split(",");
    var self = this;
    var records = [];
    setTimeout(function () {
      i++;
      if(i < lines.length -1) {
        self.totalRecords = lines.length -2;
        var obj = {};
        var currentline = lines[i].split(",");   
        for (var j = 0; j < headers.length; j++) {
          if (headers[j]) obj[headers[j].toString()] = currentline[j];
        }          
        if (obj['tracking_number']) {   
          console.log(obj);
          records.push(obj);
          self.firebaseService.addTracking(obj);
          console.log(obj);
          self.recordsImported++;
        }
        self.csvJSON(csvText, i);
      }
      
    }, 10);
    console.log(records);
    console.log(this.recordsImported);
  }


  onLogIn() {
    this.firebaseService.login(this.loginForm.value).subscribe(response => {
      if (response) { this.loggedIn = true; }
    });
  }

  onLogOut() { this.loggedIn = false; }
}